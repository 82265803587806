.event-select__menu-list {
    color: #000;
}

.event-select__placeholder {
    color: #6c757d;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
}
